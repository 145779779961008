import React, { useState, useEffect } from "react";

export default function Verzonden() {
  const [verzonden, setVerzonden] = useState([]);
  const [actergrond, setAchtergrond] = useState();
  const [title, setTitle] = useState();
  //const [actergrond, setAchtergrond] = useState();

  useEffect(() => {
    const getInfo = async () => {
      let username = "eflavours";
      let password = "an1uZm!rrD3pP#C$%m";
      let url = `https://admin.frankraes.be/wp-json/wp/v2/pages/123`;
      let authString = `${username}:${password}`;
      let headers = new Headers();
      headers.set("Authorization", "Basic " + btoa(authString));
      const response = await fetch(url, { method: "GET", headers: headers });
      if(!response.ok) {
        return "Loading...";
      }
      const data = await response.json();
      setTitle(data.title.rendered);
      setVerzonden(data.content.rendered);
    };
    const getAchtergrond = async () => {
      let username = "eflavours";
      let password = "an1uZm!rrD3pP#C$%m";
      let url = `https://admin.frankraes.be/wp-json/wp/v2/pages/123`;
      let authString = `${username}:${password}`;
      let headers = new Headers();
      headers.set("Authorization", "Basic " + btoa(authString));
      const response = await fetch(url, { method: "GET", headers: headers });
      if(!response.ok) {
        return "Loading...";
      }
      const data = await response.json();
      const mediaid = data.featured_media;
      //console.log(mediaid)
      let username2 = "eflavours";
      let password2 = "an1uZm!rrD3pP#C$%m";
      let url2 = `https://admin.frankraes.be/wp-json/wp/v2/media/${mediaid}`;
      let authString2 = `${username2}:${password2}`;
      let headers2 = new Headers();
      headers.set("Authorization", "Basic " + btoa(authString2));
      const response2 = await fetch(url2, {
        method: "GET",
        headers2: headers2,
      });
      if(!response.ok) {
        return "Loading...";
      }
      const data2 = await response2.json();
      //console.log(data2)
      setAchtergrond(data2.source_url);
    };
    
    getInfo();
    getAchtergrond();
    
  }, []);

  let parse = require("html-react-parser");

  return (
    <div>
      
      <div className='verzonden_conteiner'>{parse(`<div><h2>${title}</h2>${verzonden}</div>`)}</div>
    </div>
  );
}
//<img src={actergrond} alt="achtergrond" className="achtergrond" />