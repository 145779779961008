import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Alert } from "reactstrap";
//import { useParams } from "react-router";
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from "react-helmet";

export default function Contact({ match }) {
  const [actergrond, setAchtergrond] = useState();
  const [field, setField] = useState([]);
  const [formData, updateFormData] = useState({});
  const [direct, setDirect] = useState(false);
  const [alert, setAlert] = useState(false);
  const [title, setTitle] = useState();
  //const inputRef = useRef(null);
  const [metatag, setMetatag] = useState();

  useEffect(() => {
    //  background image ===================================================
    const getAchtergrond = async () => {
      let username = "eflavours";
      let password = "an1uZm!rrD3pP#C$%m";
      let url = `https://admin.frankraes.be/wp-json/wp/v2/pages/30`;
      let authString = `${username}:${password}`;
      let headers = new Headers();
      headers.set("Authorization", "Basic " + btoa(authString));
      const response = await fetch(url, { method: "GET", headers: headers });
      if (!response.ok) {
        return "Loading...";
      }
      const data = await response.json();
      setTitle(data.title.rendered);
      const head= data.yoast_head.replace(/admin.frankraes.be/g,"www.frankraes.be")
      setMetatag(head)
      const mediaid = data.featured_media;
      //console.log(mediaid)
      let username2 = "eflavours";
      let password2 = "an1uZm!rrD3pP#C$%m";
      let url2 = `https://admin.frankraes.be/wp-json/wp/v2/media/${mediaid}`;
      let authString2 = `${username2}:${password2}`;
      let headers2 = new Headers();
      headers.set("Authorization", "Basic " + btoa(authString2));
      const response2 = await fetch(url2, {
        method: "GET",
        headers2: headers2,
      });
      if (!response.ok) {
        return "Loading...";
      }
      const data2 = await response2.json();
      //console.log(data2)
      setAchtergrond(data2.source_url);
    };
    //========      FORM    ==============================================
    const getContact = async () => {
      let username = "eflavours";
      let password = "an1uZm!rrD3pP#C$%m";
      let url = `https://admin.frankraes.be/wp-json/gf/v2/forms/1`;
      let authString = `${username}:${password}`;
      let headers = new Headers();
      headers.set("Authorization", "Basic " + btoa(authString));
      const response = await fetch(url, { method: "GET", headers: headers });
      if (!response.ok) {
        return "Loading...";
      }
      const data = await response.json();

      const fields = data.fields;
      setField(fields);
      fields.map((i) => {
        if (i.type === "post_tags") {
          updateFormData({ ...formData, [`input_${i.id}`]: string });
        }
      });

    };

    getAchtergrond();
    getContact();
    //getHidden();
  }, []);

  // HANDLE SUBMIT ==================================================================
  const handleSubmit = async (event) => {
    event.preventDefault();

    let myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Basic ZWZsYXZvdXJzOmFuMXVabSFyckQzcFAjQyQlbQ=="
    );
    myHeaders.append("Content-Type", "application/json");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(formData),
      redirect: "follow",
    };

    const response = await fetch(
      "https://admin.frankraes.be/wp-json/gf/v2/forms/1/submissions",
      requestOptions
    );
    const data = await response.text();
    if (response.ok) {
      setDirect(true);
    } else {
      setAlert(true);
    }
    //if (response.ok) {alert('success')} else{alert('Please fill the required fields') }
    //{response.ok ? <Redirect to="/Home" /> : alert('Please fill the required fields')}
  };

  //  HANDLE CHANGE  ================================================================
  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [`${e.target.name}`]: e.target.value.trim(),
    });
  };

  //  Handle Query for hidden field ================================================

  let query = match.params.photoid;
  let string = JSON.stringify(query);

  //  FIELD LISTS  ==================================================================

  const fieldStatement = field.map((fieldItem) => {
    if (fieldItem.type === "textarea") {
      return (
        <li key={fieldItem.id} className="field_1_10">
          <label>{fieldItem.label}</label>
          <textarea
            type={fieldItem.type}
            name={`input_${fieldItem.id}`}
            placeholder={fieldItem.placeholder}
            value={fieldItem.value}
            onChange={handleChange}
            className="input_1_10"
          />
        </li>
      );
    } else if (fieldItem.type === "post_tags") {
      return (
        <li key={fieldItem.id} className="field_1_15">
          <input
            type={fieldItem.type}
            name={`input_${fieldItem.id}`}
            placeholder={fieldItem.placeholder}
            defaultValue={string}
            className="input_1_15"
          />
        </li>
      );
    } else {
      return (
        <li key={fieldItem.id} className="field_1_1">
          <label>{fieldItem.label}</label>
          <input
            type={fieldItem.type}
            name={`input_${fieldItem.id}`}
            value={fieldItem.value}
            placeholder={fieldItem.placeholder}
            onChange={handleChange}
            className="input_1_1"
          />
        </li>
      );
    }
  });

  //==============================================================================

  //let parse = require("html-react-parser");

  //  RETURN ========================================================================

  return (
    <div className="contactContainer">
      <Helmet>
      { ReactHtmlParser(metatag) }
      </Helmet>
      <img src={actergrond} alt="" className="achtergrond achtergrondContact" />
      <div className="title">{title}</div>
      <div>
        {direct === true ? <Redirect to="/Verzonden" /> : null}
        
      </div>
      <div id="gform_wrapper_1">
        <form onSubmit={handleSubmit} id="gform_1">
          <ul id="myUl">{fieldStatement}</ul>
          {alert === true ? (
            <div className="contact_alert">
              <Alert color="warning">
                <span className="star">*</span> Gelieve verplichte velden in te vullen.<span className="star">*</span>
              </Alert>
            </div>
          ) : null}
          <button type="submit" className="gform_submit_button_1">
            Verzend
          </button>
        </form>
      </div>
    </div>
  );
}
//{parse(`<div >${contact}</div>`)}

// <input onChange={e => setName(e.target.value)} value={name}></input>
// name={`input_${fieldItem.id}`}
