import React from "react";
import { Link } from "react-router-dom";

export default function Menu() {
  
  return (
  

      <div className='menuContainer'>
          <ul className='menu'>
            <Link to="/Home" className='menu__link'  > <li className="menu_item ">Home</li> </Link> <br />
            <Link to="/Portfolio" className='menu__link' > <li className="menu_item "> Portfolio</li></Link> <br />
            <Link to="/Bio" className='menu__link' > <li className="menu_item "> Bio</li></Link> <br />
            <Link to="/Verkoop" className='menu__link'  > <li className="menu_item "> Verkoopinfo</li> </Link> <br />
            <Link to="/Contact" className='menu__link'  > <li className="menu_item "> Contact</li></Link> <br />
          </ul>
      </div>
  
    
  );
}

//<Link to="/Contact" className='menu__link'  > <li className="menu_item "> Contact</li></Link> <br />

// <Link to="/Contact2" className='menu__link'  > <li className="menu_item "> Contact2</li></Link> <br />