import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Home() {
  useEffect(() => {
    getHome();
  }, []);

  //const [home, setHome] = useState([]);
  const [actergrond, setAchtergrond] = useState([]);
  const [metatag, setMetatag] = useState();

  const getHome = async () => {
    const response = await fetch(
      "https://admin.frankraes.be/wp-json/wp/v2/pages/32"
    );
    const data = await response.json();
    //setHome(data.content.rendered);
    const head = data.yoast_head.replace(
      /admin.frankraes.be/g,
      "www.frankraes.be"
    );
    setMetatag(head);

    const arrayofobjects = data.acf.slider;

    let myimages = arrayofobjects.map(function (d) {
      return {
        id: d.id,
        url: d.url,
      };
    });
    setAchtergrond(myimages);
  };
  console.log(actergrond);
  let settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 3000,
    cssEase: 'ease-out',
    fade: true
  };

  return (
    <div className="home_conteiner">
      <Helmet>{ReactHtmlParser(metatag)}</Helmet>

      <div className="achtergrond_home">
        <Slider {...settings}> {actergrond.map(photo => <img key={photo.id} src={photo.url} alt=""/>)}</Slider>
      </div>
    </div>
  );
}

//import SwiftSlider from 'react-swift-slider';
//import BackgroundSlider from "react-background-slider";
//import ImageGallery from "react-image-gallery";

// <BackgroundSlider images={actergrond} duration={2.5} transition={2} />
// {parse(`<div class='home_content'>${home}</div>`)}
// let parse = require("html-react-parser");
// <div className='achtergrond_home'><SwiftSlider data={actergrond} enableNextAndPrev={false} showDots={false}  interval={3000}/> </div>
// let myimages = arrayofobjects.map( function(d){
//   return{
//     'id': d.id,
//     'src': d.url}
// });

/* <ImageGallery
          items={actergrond}
          infinite={true}
          autoPlay={true}
          slideDuration={1000}
          showFullscreenButton={false}
          showNav={false}
          showThumbnails={false}
          showPlayButton={false}
          
        /> */

// const getAchtergrond = async () => {
//   const response = await fetch(
//     `https://admin.frankraes.be/wp-json/wp/v2/pages/32`
//   );
//   const data = await response.json();
//   const arrayofobjects = data.acf.slider; // this is my array of objects
//   // const one_url = data.acf.slider[0].url // this is what I want from every array element
//   //const myimages = arrayofobjects.map((d) => d.url);

//   let myimages = arrayofobjects.map( function(d){
//     return{
//       'id': d.id,
//       'src': d.url}
//   });

//   setAchtergrond(myimages);
// };
