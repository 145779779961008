import React, { useState, useEffect } from "react"
import ReactHtmlParser from 'react-html-parser'
import { Helmet } from "react-helmet"


export default function Bio() {
  useEffect(() => {
    getBio();
    getAchtergrond();

  }, []);

  const [bio, setBio] = useState([]);
  const [achtergrond, setAchtergrond] = useState();
  const [title, setTitle] = useState();
  const [metatag, setMetatag] = useState();

  const getBio = async () => {
    let username = "eflavours";
    let password = "an1uZm!rrD3pP#C$%m";
    let url = `https://admin.frankraes.be/wp-json/wp/v2/pages/9`;
    let authString = `${username}:${password}`;
    let headers = new Headers();
    headers.set("Authorization", "Basic " + btoa(authString));
    const response = await fetch(url, { method: "GET", headers: headers });
    if (!response.ok) {
      return "Loading...";
    }
    const data = await response.json();
    setTitle(data.title.rendered);
    setBio(data.content.rendered);
    const head= data.yoast_head.replace(/admin.frankraes.be/g,"www.frankraes.be")
    setMetatag(head)
  };
  var parse = require("html-react-parser");

  const getAchtergrond = async () => {
    let username = "eflavours";
    let password = "an1uZm!rrD3pP#C$%m";
    let url = `https://admin.frankraes.be/wp-json/wp/v2/pages/9`;
    let authString = `${username}:${password}`;
    let headers = new Headers();
    headers.set("Authorization", "Basic " + btoa(authString));
    const response = await fetch(url, { method: "GET", headers: headers });
    if (!response.ok) {
      return "Loading...";
    }
    const data = await response.json();
    const mediaid = data.featured_media;
    //console.log(mediaid)
    let username2 = "eflavours";
    let password2 = "an1uZm!rrD3pP#C$%m";
    let url2 = `https://admin.frankraes.be/wp-json/wp/v2/media/${mediaid}`;
    let authString2 = `${username2}:${password2}`;
    let headers2 = new Headers();
    headers.set("Authorization", "Basic " + btoa(authString2));
    const response2 = await fetch(url2, { method: "GET", headers2: headers2 });
    if (!response.ok) {
      return "Loading...";
    }
    const data2 = await response2.json();
    //console.log(data2)
    setAchtergrond(data2.source_url);
  };


  return (
    <div>
      <Helmet>
      { ReactHtmlParser(metatag) }
      </Helmet>

      <div className="title">{title}</div>
      <div className="bio_conteiner">
        <div className="bio_text">{parse(`<div class='bio_content'>${bio}</div>`)}</div>
        <img src={achtergrond} alt="" className="achtergrond_bio" />
      </div>
    </div>
  );
}

// class='bio_content'
//<img src={achtergrond} alt='achtergrond' className='achtergrond'/>
// <div className='achtergrond'><BackgroundSlider images={achtergrond} duration={2.5} transition={2} /></div>

//   const newData = data.forEach(function(record) {
//     if (record.url === "https://admin.frankraes.be/") {
//         record.url = "https://www.frankraes.be/";
//     }
// });

//console.log(JSON.parse(JSON.stringify(result.head).replace(/url/g, "something")))
