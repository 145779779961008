// const browser = navigator.userAgent
// const isFirefox = browser.indexOf('Firefox') > -1
// isFirefox ? '#000000' :
const options = {
    settings: {
      autoplaySpeed: 2500,
      disableKeyboardControls: false,
      disablePanzoom: false,
      disableWheelControls: false,
      hideControlsAfter: 3000,
      lightboxTransitionSpeed: 0.6,
      lightboxTransitionTimingFunction: "linear",
      overlayColor: 'rgba(0,0,0,0.9)',
      slideAnimationType: "fade",
      slideSpringValues: [300, 200],
      slideTransitionSpeed: 0.6,
      slideTransitionTimingFunction: "linear",
    },
    buttons: {
      backgroundColor: "rgba(30,30,36,0.8)",
      iconColor: "rgba(255, 255, 255, 0.8)",
      iconPadding: "5px",
      showAutoplayButton: true,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: true,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: true,
      size: "40px",
    },
    caption: {
      captionAlignment: "start",
      captionColor: "#FFFFFF",
      captionContainerPadding: "20px 10px",
      captionFontFamily: "Montserrat, sans-serif",
      captionFontSize: "18px",
      captionFontStyle: "inherit",
      captionFontWeight: "700",
      captionTextTransform: "uppercase",
      showCaption: true,
    },
    thumbnails: {
      showThumbnails: true,
      thumbnailsAlignment: "center",
      thumbnailsContainerBackgroundColor: "transparent",
      thumbnailsContainerPadding: "0",
      thumbnailsGap: "1px",
      thumbnailsOpacity: 0.4,
      thumbnailsPosition: "bottom",
      thumbnailsSize: ["100px", "80px"],
    },
    progressBar: {
      height: "3px",
      fillColor: "#FE938C",
      backgroundColor: "rgba(43, 45, 66, 0.9)",
    },
  };
  
  export default options;