import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import a from "./a.jpg";

export default function Portfolio() {
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    getPortfolio();
  }, []);

  const getPortfolio = async () => {
    let username = "eflavours";
    let password = "an1uZm!rrD3pP#C$%m";
    let url = `https://admin.frankraes.be/wp-json/wp/v2/media_cat/?hide_empty=true&per_page=99`;
    let authString = `${username}:${password}`;
    let headers = new Headers();
    headers.set("Authorization", "Basic " + btoa(authString));
    const response = await fetch(url, { method: "GET", headers: headers });
    const data = await response.json();
    setPortfolio(data);
  };

  //const placeholder = portfolio.map( d => ()=> { (d.acf.media_cat_image.sizes.medium === undefined) return a )}
      
  

  const catFotos = portfolio.map((d) =>(
    <div key={d.id} className="albumcover">
      <div className="inner">
        
        <Link to={`/Portfolio/${d.id}`} className="linkto">
          <img
            src={d.acf.media_cat_image.sizes.medium}
            alt="album_covers"
            className="portfolioimage"
          />
          <div className="nameWrapper">
            <p className="img_name">{d.name}</p>
          </div>
        </Link>{" "}
        
      </div>
    </div> 
  ));

  return (
    <div>
        <div className="acContainer">{catFotos}</div> 
     
    </div>
  );
}

//<header>{isShown && d.name}</header>
//<Link to={`/Portfolio/${d.id}`} className='linkto'> ... </Link>

//`https://frankraes.x03.be/wp-json/wp/v2/media_cat/?hide_empty=true`
