import React, { useState } from "react"
import { BrowserRouter, Switch, Route} from "react-router-dom"
import Menu from "./components/Menu"
import Bio from "./components/Bio"
import Contact from "./components/Contact"
import Portfolio from "./components/Portfolio"
import Verkoop from "./components/Verkoop"
import scss from "./scss/app.scss"
import Album from "./components/albums/Album"
import Home from "./components/Home"
import Header from "./components/Header"
import { SiInstagram } from "react-icons/si"
import Verzonden from "./components/Verzonden"


function App() {
  
  const [ismenuopen, setIsmenuopen] = useState(false)
 

  return (
    <div className='allecontent'>
      <BrowserRouter>
        <Header
          ismenuopen={ismenuopen}
          onToggleMenu={() => setIsmenuopen(!ismenuopen)}
          setIsmenuopen = {setIsmenuopen}
        />
        
        
        <div className="browserrouter">
            <div className="routes">
              <Switch>
                <Route path="/Home" exact component={Home} />
                <Route path="/Portfolio" exact component={Portfolio} />
                <Route path="/Portfolio/:id" component={Album} />
                <Route path="/Bio" component={Bio} />
                <Route path="/Verkoop" component={Verkoop} />
                <Route path="/Contact/:photoid" exact component={Contact} />
                <Route path="/Contact" component={Contact} />
                <Route path="/Verzonden" component={Verzonden}/>
                <Route path="/Menu" component={Menu} />
                <Route path="*" exact component={Home}/>
                
              </Switch>
            </div>
        </div>
        

        <footer>
          <div className='footerContainer'>
            <small className='footerinfo'> © Alle rechten voorbehouden /
            
              Flavoured by <a href="https://www.eflavours.be/" target='_blank' rel='noreferrer' > <span id="eflavours">eFlavours</span></a>
            
            </small>
            
            <a href='https://www.instagram.com/frankraesbrasil/' target='_blank' rel='noreferrer noreferrer'>
            <SiInstagram className='footericon'/>
            </a>
          </div>
        </footer>
      </BrowserRouter>
    </div>
  );
}

export default (App);

//<Route path="/Navmenu" component={Navmenu} onClick={()=>setOpen(false)} />

//onClick={()=>setOpen (!open)}

// function isOpened(){
//  setOpen (!open)
//  if (<Link to='/Navmenu' />){ return 'close it'}
//  else { return 'open it'} }

// <footer>{ '/Home' ? null : <small> © Alle rechten voorbehouden</small>}</footer>

// <Route path="/Contact2" component={Contact2} />

//<small> Powerd by <a href="https://www.eflavours.be/"> <span id="eflavours">Eflavours</span></a></small>