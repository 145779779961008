import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from "simple-react-lightbox";
import options from "./options";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";

function Album({ match }) {
  const [data, setData] = useState([]);


  const fetchItem = useCallback(async () => {
   
    const fetchItem = await fetch(
      `https://admin.frankraes.be/wp-json/wp/v2/media?per_page=99&media_cat=${match.params.id}`
    );
    const fdata = await fetchItem.json();
    setData((prevData) => {
      return [...prevData, ...fdata];
    });
  }, []);

  const tags = data.map((d) =>
    d.yoast_head.replace(/admin.frankraes.be/g, "www.frankraes.be")
  );

  useEffect(() => {
    fetchItem();
  }, []);

  // const handleId = ()=>{
  //   let ids = data.map(i => i.title.rendered)
  //   setId(ids)
  // }
  const dataCapL = data.length;
  let captionArray = [];
  for (let i = 0; i < dataCapL; i++) {
    if (data[i].media_details.height > data[i].media_details.width) {
      data[i]["portclass"] = "fotoContainer portrait";
    } else {
      data[i]["portclass"] = "fotoContainer landscape";
    }
    captionArray[i] = {
      id: i,
      caption: (
        <div className="SRLCustomCaption" id="infoContainer">
          <div className="SRLCustomCaption" id="caption">
            {data[i].title.rendered}
          </div>
          <Link
            to={`/Contact/id: ${data[i].id} && title: ${data[i].title.rendered}`}
            className="SRLCustomCaption"
            id="linkinfo"
          >
            <span className="SRLCustomCaption" id="infobutton">
              Meer Info
            </span>
          </Link>
        </div>
      ),
    };
  }
  const albumFotos = (
    <SRLWrapper options={options} customCaptions={captionArray}>
      {!data ? (
        <div>
          <div>
            <h1>LOADING...</h1>
          </div>
        </div>
      ) : (
        <div>
          <div className="allAlbumFotos">
            {data.map((d) => (
              <div key={d.id} className={d.portclass}>
                <a href={d.source_url} data-attribute="SRL">
                  <img
                    src={d.media_details.sizes.medium.source_url}
                    alt={d.title.rendered}
                    className="eachFoto"
                  />
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </SRLWrapper>
  );

  return (
    <div>
      <Helmet>{ReactHtmlParser(tags)}</Helmet>
      <div>
        <SimpleReactLightbox>{albumFotos}</SimpleReactLightbox>
      </div>
    </div>
  );
}
export default Album;

// if you cannot get something from the fetchedItem because it is undefined
// put that into useState object as something like images:{}
//_photo_name:${data[i].title.rendered}

//<a href={`/Contact?photoid=${data[i].id}` } ></a>
