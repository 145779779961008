import React, { useEffect } from "react"
import Menu from "./Menu"
import {Link} from "react-router-dom"
import { withRouter } from "react-router-dom"
import { VscGrabber } from "react-icons/vsc"
import { VscChromeClose } from "react-icons/vsc"

function Header({ ismenuopen, onToggleMenu, setIsmenuopen, history }) {
  useEffect(() =>
    history.listen(() => {
      setIsmenuopen(false)
    }),[]
  );
  return (
    <div>
      <div className='headerContainer'>
        <div className='headerText'> <Link to='/Home' className='headerlink' >Frank Raes </Link></div>
        <div onClick={onToggleMenu} className='headerButton'>
            {ismenuopen ?
            <VscChromeClose/> :< VscGrabber /> }
        </div>
      </div>
      {ismenuopen && <Menu />}
    </div>
  );
}

export default withRouter(Header)

//import hicon from "./hicon.png"
//<img src={hicon} alt='hamburger_menu'/>